<template>
    <div class="basic-data">
        <catalog-tree
            class="catalog"
            :menulist="menulist"
            :defaultProps="defaultProps"
            :expandedKey.sync="itemId"
            :currentKey.sync="itemId"
            @handleNodeClick="handleNodeClick"
        >
        </catalog-tree>
        <router-view
            ref="route"
            class="basic-container flex-1"
            :itemName="itemName"
            :itemId="itemId"
            :currentChild.sync="currentChild"
        ></router-view>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import catalogTree from "@comp/catalog_tree.vue";
import mixin from "../mixin";
export default {
    name: "basic-data",
    components: {
        catalogTree,
    },
    mixins: [mixin],
    data() {
        return {
            menulist: [],

            defaultProps: {
                children: "Children",
                label: "MC",
            },
            basicType: "",
            itemId: "",
            itemName: "",
            currentChild: {},
        };
    },
    computed: {
        ...mapState({
            departmentId: (state) => state.login.departmentId,
        }),
        ...mapGetters(["system"]),
    },
    mounted() {
        this.getTreeData();
    },
    methods: {
        ...mapActions(["GetBasicLeftTree"]),
        // 获得基础数据左侧列表数据
        async getTreeData() {
            let result = await this.GetBasicLeftTree({
                lb: 0,
                departmentId: localStorage.userId,
            });
            // 林业系统 没有父级菜单
            if (this.system == "system2" || this.system == "system3") {
                let list = [];
                result.map((item) => {
                    let state = false;
                    item.Children.map((itemInner1) => {
                        if (itemInner1.Children.length) {
                            state = true;
                        }
                        list = [...list, ...itemInner1.Children];
                    });
                    if (!state) {
                        list = [...list, ...item.Children];
                    }
                });

                list.map((item) => {
                    switch (item.ItemID) {
                        case "22": // "管理制度和应急预案记录"
                            item.MC = "消防制度";
                            break;
                        case "17": // "保护管理机构"
                            item.MC = "机构管理";
                            break;
                        case "24": // "保护展示与环境整治工程记录"
                            item.MC = "林业项目";
                            break;
                    }
                });
                this.menulist = list || [];
            } else {
                this.menulist = result || [];
            }
            this.itemName = this.menulist.length ? this.menulist[0].MC : "";
            this.itemId = this.menulist.length ? this.menulist[0].ItemID : "";

            let paramID = this.$route.params.ItemID;
            let infoPageID = this.$route.query.ItemID;
            let store = JSON.parse(localStorage.getItem("basicCurrent"));
            if (
                store &&
                store.ItemID &&
                (store.ItemID === paramID || store.ItemID === infoPageID)
            ) {
                this.currentChild = store.params;
            } else {
                this.currentChild = this.getItemName();
                this.changeCurrent();
            }
        },
        getFirstChild(list) {
            if (list.Children && list.Children.length > 0) {
                return this.getFirstChild(list.Children[0]);
            } else if (
                (list.Children && list.Children.length === 0) ||
                !list.Children
            ) {
                return list;
            }
        },
        changeCurrent() {
            if (!this.currentChild) {
                this.currentChild = this.getFirstChild(this.menulist[0]);
            }
            const basicCurrent = JSON.stringify({
                ItemID: String(this.currentChild.ItemID),
                params: this.currentChild,
            });
            localStorage.setItem("basicCurrent", basicCurrent);
            localStorage.setItem("basicPower", this.currentChild.CZQX || "0");
        },
        handleNodeClick(data) {
            let fileType = ["drawing", "document", "picture", "image"]; // 图纸、文档、图片、影像
            this.basicType = fileType[Number(data.LXMC) - 1];
            data.basicType = this.basicType;
            this.itemId = data.ItemID;
            this.itemName = data.MC;
            this.currentChild = data;
            this.changeCurrent();
            this.$router
                .push({
                    name: "basicTable",
                    params: this.currentChild,
                })
                .then(() => {
                    this.$refs.route.changeModule(data);
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.basic-data {
    height: calc(100% - 30px);
    width: 100%;
    display: flex;
    flex-flow: row nowrap;

    .basic-container {
        background: #f7f5fb;
    }
}
</style>
