<template>
    <div id="catalog-tree" class="scrollbar">
        <el-tree
            ref="tree"
            :data="menulistResult"
            :props="defaultProps"
            accordion
            icon-class=""
            :node-key="keyNode"
            :default-expanded-keys="[expandedKey]"
            :default-checked-keys="[checkedKey]"
            :current-node-key="currentKey"
            :indent="indent"
            @node-click="handleNodeClick"
        >
        </el-tree>
    </div>
</template>

<script>
export default {
    name: "catalog-tree",
    components: {},
    props: {
        menulist: {
            type: Array,
            default: () => [],
        },
        defaultProps: {
            type: Object,
            default: () => ({
                children: "children",
                label: "MC",
            }),
        },
        keyNode: {
            type: String,
            default: () => "ItemID",
        },
        expandedKey: {
            type: String,
            default: () => "1",
        },
        checkedKey: {
            type: String,
            default: () => "",
        },
        currentKey: {
            type: String,
            default: () => "1",
        },
    },
    data() {
        return {
            indent: 14,
            menulistResult: [],
        };
    },
    watch: {
        menulist: {
            deep: true,
            handler: function(list) {
                let result = JSON.parse(JSON.stringify(list));
                this.menulistResult = result;
                this.$nextTick(function() {
                    let ItemID = String(this.getCurrentKey());
                    this.$refs.tree.setCurrentKey(ItemID);
                    this.$emit("update:expandedKey", ItemID);
                });
            },
        },
        currentKey(val) {
            this.setLocalData(val);
        }
    },
    methods: {
        handleNodeClick(data) {
            // TODO
            let current = this.$route.params[this.keyNode];
            let children = data[this.defaultProps.children];
            if (
                (children && children.length > 0) ||
                current === data[this.keyNode]
            )
                return false;
            this.$emit("handleNodeClick", data);
        },
        setLocalData(val) {
            localStorage.setItem('requestItemID', val);
            if (val) {
                let node = this.$refs.tree.getNode(val);
                let name = node && node.data && node.data.MC || "";
                localStorage.setItem('requestItemName', name);
            } else {
                localStorage.setItem('requestItemName', "");
            }
        },
        getCurrentKey() {
            const route = this.getCurrentRoute();
            const {
                currentRoute: { params },
            } = this.$router;
            if (params.ItemID) {
                return params.ItemID;
            } else {
                return route.itemId;
            }
        },
        getCurrentRoute() {
            const {
                currentRoute: { name },
                options: { routes },
            } = this.$router;
            function findRoute(routes, name) {
                const _routes = [];
                for (const route of routes) {
                    if (route.name === name) {
                        return route;
                    } else if (route.children && route.children.length > 0) {
                        _routes.push(...route.children);
                    }
                }
                return findRoute(_routes, name);
            }
            return findRoute(routes, name);
        },
    },
    destroyed() {
        localStorage.setItem('requestItemID', "");
        localStorage.setItem('requestItemName', "");
    }
};
</script>

<style lang="scss" scoped>
#catalog-tree {
    box-shadow: 2px 1px 2px #ccc;
    z-index: 1;
    width: 300px;
    height: 100%;
}
</style>
