export default {
    methods: {
        getCurrentKey() {
            const route = this.getCurrentRoute();
            const params = this.$router.currentRoute.params;
            if (params.ItemID) {
                return params.ItemID;
            } else {
                return route.itemId;
            }
        },
        getCurrentRoute() {
            const { currentRoute: { name }, options: { routes } } = this.$router;

            function findRoute(routes, name) {
                const _routes = [];
                for (const route of routes) {
                    if (route.name === name) {
                        return route;
                    } else if (route.children && route.children.length > 0) {
                        _routes.push(...route.children);
                    }
                }
                return findRoute(_routes, name);
            }
            return findRoute(routes, name);
        },
        getCurrentTree() {
            const childrenKey = this.defaultProps.children;
            const currentKey = this.getCurrentKey();

            function getTreeItem(menulist, ItemID) {
                for (const menu of menulist) {
                    if (String(menu.ItemID) === String(ItemID)) {
                        return menu;
                    } else if (menu[childrenKey] && menu[childrenKey].length > 0) {
                        const res = getTreeItem(menu[childrenKey], ItemID);
                        if (res) {
                            return res;
                        }
                    }
                }
            }
            if (this.menulist && this.menulist.length > 0) {
                const menulist = this.menulist;
                const currentChild = getTreeItem(menulist, currentKey);
                return currentChild;
            }
        },
        getItemName() {
            const currentChild = this.getCurrentTree();
            // console.error((this.itemId) !== (currentChild.ItemID), currentChild);
            if (currentChild && (Number(this.itemId) !== Number(currentChild.ItemID))) {
                this.currentChild = currentChild;
                this.itemName = currentChild.MC;
                this.itemId = String(currentChild.ItemID);
            }
            // else {
            //     setTimeout(() => {
            //         this.getItemName();
            //     }, 1500);
            // }
            return currentChild;
        },
    }
};